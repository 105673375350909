<template>
  <div id="app" class="_font-primary _overflow-x-hidden _max-w-full">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/> -->
    <nav
      class="_fixed _w-full _py-5 _z-[40] _transition"
      :class="{
        '_bg-[#326DE1]': $route.name != 'Home' || !view.topOfPage,
        '_bg-transparent': $route.name == 'Home' && view.topOfPage,
      }"
    >
      <div
        class="_container _flex _items-center _px-0 _justify-end xl:_justify-between"
      >
        <img
          :src="require('@/assets/images/rocknet-logo.png')"
          class="_h-[30px] md:_h-[50px] _mr-auto xl:_mr-0"
        />
        <ul
          class="topnav _menu _menu-horizontal _absolute _top-[90px] _right-0 _flex-col _bg-white _w-full _transition [&>li>a]:_w-full [&>li>a]:_py-7 xl:[&>li>a]:_py-3 xl:_gap-5 xl:_opacity-100 xl:_visible xl:_w-auto xl:_bg-transparent xl:[&>li>a]:_w-auto xl:_flex xl:_top-auto xl:_right-auto xl:_relative xl:_flex-row xl:[&>li>a]:_text-white xl:[&>li>a]:_font-normal xl:[&>li>a]:_rounded-xl xl:[&>li>a:hover]:_bg-white xl:[&>li>a:hover]:_text-primary"
          :class="toggleNav ? '_visible _opacity-100' : '_invisible _opacity-0'"
        >
          <li class="sm:_hidden _p-2">
            <a
              href="https://my.rocknet.co.id/"
              class="_btn _text-primary _btn-outline _rounded-xl !_p-[2px] _h-[32px] lg:_h-auto _inline-flex"
            >
              <img
                :src="require('@/assets/images/myrocknet-logo.png')"
                class="_w-[100px]"
              />
            </a>
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({ name: 'Home', params: { lang: bhs } }).href
              "
              :class="{ active: active_page == 'Home' }"
              >{{ $t("nav.home") }}</a
            >
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({ name: 'Paket', params: { lang: bhs } }).href
              "
              :class="{ active: active_page == 'Paket' }"
              >{{ $t("nav.package") }}</a
            >
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({
                  name: 'InformasiIndex',
                  params: { lang: bhs },
                }).href
              "
              :class="{
                active:
                  active_page == 'InformasiIndex' ||
                  active_page == 'InformasiDetail',
              }"
              >{{ $t("nav.information") }}</a
            >
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({ name: 'FAQ', params: { lang: bhs } }).href
              "
              :class="{ active: active_page == 'FAQ' }"
              >{{ $t("nav.faq") }}</a
            >
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({ name: 'Berlangganan', params: { lang: bhs } })
                  .href
              "
              :class="{ active: active_page == 'Berlangganan' }"
              class=""
              >{{ $t("nav.subscribe") }}</a
            >
          </li>
          <li class="">
            <a
              :href="
                $router.resolve({ name: 'Bantuan', params: { lang: bhs } }).href
              "
              :class="{ active: active_page == 'Bantuan' }"
              >{{ $t("nav.contactus") }}</a
            >
          </li>
        </ul>
        <div class="_flex _gap-3">
          <a
            href="https://my.rocknet.co.id/"
            class="_btn _bg-white _text-primary hover:_bg-gray-200 _border-transparent _h-[32px] lg:_h-auto _hidden sm:_inline-flex"
          >
            <img
              :src="require('@/assets/images/myrocknet-logo.png')"
              class="_w-[100px]"
            />
          </a>
          <div class="_dropdown _dropdown-end">
            <button
              tabindex="0"
              class="_btn _btn-accent _btn-outline _rounded-btn"
            >
              <Icon
                icon="mdi:web"
                class="_text-[12px] _mr-2 _hidden sm:_inline"
              />
              {{ $i18n.locale }}
              <Icon
                icon="mdi:chevron-down"
                class="_text-[12px] _ml-2 _hidden sm:_inline"
              />
            </button>
            <ul
              tabindex="0"
              class="_menu _dropdown-content _p-2 _shadow _bg-base-100 _rounded-box _w-52 _mt-4"
            >
              <li v-for="(item, i) in $i18n.availableLocales" :key="i">
                <a class="_text-left _uppercase" @click="setLocale(item)">{{
                  item
                }}</a>
              </li>
            </ul>
          </div>
          <button
            class="_btn _btn-square _btn-ghost _inline-flex _ml-1 xl:_hidden"
            @click="toggleNav = !toggleNav"
          >
            <Icon icon="mdi:menu" class="_text-white _text-[32px]" />
          </button>
        </div>
      </div>
    </nav>
    <div class="_min-h-screen">
      <router-view></router-view>
    </div>
    <section class="_bg-primary _px-5 _py-10">
      <div class="_container">
        <div class="_text-center _text-white">
          <div class="_text-[30px] _mb-5">
            {{ $t("footer.text") }}
            <strong class="_block sm:_inline">0811-1002-0929</strong>
          </div>
          <div class="_flex _justify-center _gap-3">
            <a
              href="https://wa.me/081210107861"
              class="_btn _btn-accent _rounded-full"
              target="_blank"
            >
              <Icon
                icon="akar-icons:whatsapp-fill"
                width="24"
                height="24"
                class="_mr-3"
              />
              {{ $t("footer.wa-text") }}
            </a>
            <a
              href=" https://www.instagram.com/rocknet.official/"
              class="_btn _btn-accent _rounded-full"
              target="_blank"
            >
              <Icon
                icon="akar-icons:instagram"
                width="24"
                height="24"
                class="_mr-3"
              />
              {{ $t("footer.ig-text") }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="_bg-black _py-10">
      <div class="_container">
        <div class="_grid _grid-cols-6 _gap-10 _pb-10">
          <div class="">
            <img
              :src="require('@/assets/images/rocknet-logo.old.png')"
              class="_h-[30px] md:_h-[50px] _mr-auto xl:_mr-0"
            />
          </div>
          <div class="_col-span-2 _text-white _text-left">
            <div class="_font-bold _text-sm _mb-5">Menu</div>
            <ul
              class="[&>li>a]:_text-white [&>li>a]:_underline _flex _flex-col _gap-3"
            >
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'Bantuan',
                      params: { lang: bhs },
                    }).href
                  "
                  >Contact Us</a
                >
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'TermsCondition',
                      params: { lang: bhs },
                    }).href
                  "
                  >Syarat & Ketentuan</a
                >
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'PrivacyPolicy',
                      params: { lang: bhs },
                    }).href
                  "
                  >Kebijakan Privasi</a
                >
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'RefundPolicy',
                      params: { lang: bhs },
                    }).href
                  "
                  >Return & Refund Policy</a
                >
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'DeliveryPolicy',
                      params: { lang: bhs },
                    }).href
                  "
                  >Delivery Policy</a
                >
              </li>
            </ul>
          </div>
          <div
            class="_col-span-3 _flex _justify-end _-mx-[15px] _text-white _mb-5"
          >
            <div
              class="_px-[15px] _w-full _text-left _max-w-lg"
              v-for="(item, i) in dataContactAddress"
              :key="i"
            >
              <div
                class="_font-bold _text-sm _mb-5"
                v-html="item.Title + ' : '"
              ></div>
              <div class="_text-sm">
                <div v-html="item.Address"></div>
                Call/WA :
                <a
                  :href="'https://wa.me/' + item.Whatsapp"
                  class="_underline _font-bold"
                  target="_blank"
                  >{{ item.Whatsapp }}</a
                >
              </div>
            </div>

            <!-- <div class="_text-left">
              <div class="_font-bold _text-sm _mb-5">Alamat Rocknet Depok : :</div>
              <div class="_text-sm">
                JJl. H Sulaiman No. 25 RT 004/006 <br/>
                Kec Sawangan - Kota Depok <br/>
                Call/WA : <a href="https://wa.me/081210107961" class="_underline _font-bold" target="_blank">0812-1010-7961</a>
              </div>
            </div>
            <div class="_text-left">
              <div class="_font-bold _text-sm _mb-5">Alamat Rocknet Minut :</div>
              <div class="_text-sm">
                Sarongsong 1 Lingkungan 2 (Komplek Pasar)  <br/>
                Kec Airmadidi - Kab Minahasa Utara <br/>
                Call/WA : <a href="https://wa.me/081210107865" class="_underline _font-bold" target="_blank">0812-1010-7865</a>
              </div>
            </div>
           -->
          </div>
        </div>
      </div>
      <hr class="_border-[#343434]" />
      <div class="_container _text-center _py-5 _text-white">
        © 2022 Rocknet by PT Cloudwel Network Indonesia. All Rights Reserved.
      </div>
    </section>
  </div>
</template>
<script>
import { getMainContactAddress } from "./api/strapi";
import i18n from "@/i18n";

export default {
  data: () => ({
    view: {
      topOfPage: true,
    },
    bhs: i18n.locale,
    dataContactAddress: [
      {
        Title: "Alamat Rocknet Bekasi",
        Address: ` Jl. Desa Satriajaya RT 003/006 (sebelah Alfamart) <br/>
          Kec Tambun Utara - Kab Bekasi <br/>`,
        Whatsapp: "081210107861",
      },
    ],
    toggleNav: false,
    active_page: "",
  }),
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.active_page = this.$route.name;
    this.setMainContactAddress();
  },
  methods: {
    async setMainContactAddress() {
      await getMainContactAddress({
        locale: this.bhs,
        populate: {
          addresses: {
            populate: ["Title", "Address", "Whatsapp"],
          },
        },
      }).then((res) => {
        if (res) {
          console.log("footer");
          // console.log(res)
          const { data } = res;
          if (res.status == 200) {
            const mainData = data.data.attributes.addresses.data;
            console.log(mainData);
            this.dataContactAddress = [];
            mainData.forEach((item) => {
              this.dataContactAddress.push({
                Title: item.attributes.Title,
                Address: item.attributes.Address,
                Whatsapp: item.attributes.Whatsapp,
              });
            });
          }
        }
      });
    },
    blurBtn() {
      setTimeout(() => {
        this.toggleNav = false;
      }, 100);
    },
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    setLocale(locale) {
      var Rname = this.$route.name;
      // var refBtnLang = this.$refs.btnLang
      this.$router.push({ name: Rname, params: { lang: locale } });
      this.$router.go(0);
      // this.$i18n.locale = locale
    },
  },
  watch: {
    $route(to) {
      this.toggleNav = false;
      this.active_page = to.name;
    },
  },
  // beforeRouteLeave (from,to,next) {
  //   this.toggleNav = false
  //   next()
  // },
};
</script>
<style lang="scss">
#app {
  @apply _font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

ul.topnav > li > .active,
ul.topnav > li > .router-link-active {
  background: white;
  color: #326de1 !important;
}
nav {
  padding: 30px;
}
</style>
