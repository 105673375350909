import axios from 'axios';
import qs from 'qs';


const api = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API,
})

api.interceptors.request.use(config => {
  // Do something before request is sent
//   const token =  process.env.VUE_APP_STRAPI_TOKEN
//   console.log(token)
  // eslint-disable-next-line no-param-reassign
//   if(token) {
//       config.headers['Authorization'] = `Bearer ${token}`
//   }

  return config
},
(error) => {
    return Promise.reject(error);
})


export const getPaket = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
        .get('/api/paket?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const register = async (body) => {
  try {
     
      const response = await api
      .post('/api/auth/register',body);
      return response;
  } catch (error) {
      return error.response;
  }
}



export const domisili = async (domi,id) => {
  try {
    const response = await api.get(`/api/${domi}/get/${id}`)
    return response
  } catch (error) {
    return error.response
  }
}

export const listPaket = async () => {
  try {
    const response = await api.get(`/api/paket`)
    return response
  } catch (error) {
    return error.response
  }
}