import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { Icon } from '@iconify/vue2';
import * as VueGoogleMaps from 'vue2-google-maps'
import NotFound from '@/components/NotFound';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect'
import VeeValidate from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import vuescroll from "vuescroll";
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
// You can set global config here.
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
  name: "vScroll", // customize component name, default -> vueScroll
});

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import i18n from './i18n'


Vue.use(VueSweetalert2);

Vue.use(VeeValidate);
// register globally
Vue.component('v-select', Multiselect)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Icon",Icon)
Vue.config.productionTip = false
Vue.component("NotFound",NotFound)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUC6IY1uGllqyiZ4Twb9Rj7oXcaIfb-6g',
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'id'
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
