import axios from 'axios';


const api = axios.create({
  baseURL: process.env.VUE_APP_STRAPI_URL,
})

api.interceptors.request.use(config => {
  // Do something before request is sent
  const token =  process.env.VUE_APP_STRAPI_TOKEN
//   console.log(token)
  // eslint-disable-next-line no-param-reassign
  if(token) {
      config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
},
(error) => {
    return Promise.reject(error);
})

export default api